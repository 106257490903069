import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Caption = makeShortcode("Caption");
const Aside = makeShortcode("Aside");
const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Experts in creating mechanical design components, design for manufacturing, and mechanical simulation study.`}</p>
    </PageDescription>
    <Row mdxType="Row">
      <Column colMd={5} colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACoklEQVQoz02Sa28SeRTG56P2K2x8YRez1oRUWnC1Yrsl2os2RBer6243UO2WS1XqJaGVuggDlNsMtDjMhVu5zAy/DbPenuS8OTn/X/7nOY8g5kUymQyVSoVGo4Esy05pmkan06ZttNENg5amoWo6RruNmC+QyYhIcoPk4VvOFZ1ub4DS0hFK5VOazSa9Xo+pJpMJE9vGtm1+1Hhsks1m2djYZHb2Zy7PzuL332P3+R6hrS3yuRKlWgOhVqvR6XQc4BT2o0ajkfP7zc1N5ubm8Hg8xONxFEUhnT7i0k9X2Pk7zPryEunjj5SqDYRiqeQMTIGWZaGqKsVikbt37+FyuVjweIjFYs4W0/5XXVwMWL7txev1kTr+QP28Ra2uIZzkS+iaSrfbp1KVcLvdzMzMEI3GKBQKpNMn3yBTjz+enCBJEsnkIaHgOgcHSZrqkNNKk7LUQjjKFmkbGk1Fp36mks8XuL+2wlHqvQNJvjogFos6kEgkQiKRQBSnB6nzMhEjcXDIaVWlUG5SrikIxXKFs0ads/PPXAxter0++y/CeL1+3h2+JnDbw5PtEIZhOCvvhneQJJlU6pjUhwwtvY/R7qPqPVpaF6FYLJDN5pwHivKZ1dUAtxZc3PnVx9qdBcLbG7ze2+X30GOe/fEUMfsvg8HAOaBp2pjW/4kYmxaD4RghJ+YQ83mGoxGyVCX36ZhodB/fDR83fdeZd1/j2tWrBINBxuOxY4M9mTgHtGwb07QYjkz6FyM63QFC6bSAXKti6No3859uh1j2LxH4bYVYdJ+6LH/P0mSaz6/xmkzpWKbFeGQ6JVz2P+KX1SdcfxDhZijOjUf7uNf/whV4xvzWcwI7b1j5M8mtx/EvlWBpO8FicI/5BxHm74dZDL5w+r6H//AfHJv4Q6AfPkAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/35cd4240bf50f0e0ea16072e8d0e23ad/5f1c3/solidworks.webp 288w", "/static/35cd4240bf50f0e0ea16072e8d0e23ad/c4eb6/solidworks.webp 576w", "/static/35cd4240bf50f0e0ea16072e8d0e23ad/c2ad5/solidworks.webp 1152w", "/static/35cd4240bf50f0e0ea16072e8d0e23ad/8de58/solidworks.webp 1728w", "/static/35cd4240bf50f0e0ea16072e8d0e23ad/f3ff0/solidworks.webp 1920w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/35cd4240bf50f0e0ea16072e8d0e23ad/672f0/solidworks.png 288w", "/static/35cd4240bf50f0e0ea16072e8d0e23ad/cbcfa/solidworks.png 576w", "/static/35cd4240bf50f0e0ea16072e8d0e23ad/8dd4b/solidworks.png 1152w", "/static/35cd4240bf50f0e0ea16072e8d0e23ad/93843/solidworks.png 1728w", "/static/35cd4240bf50f0e0ea16072e8d0e23ad/7d442/solidworks.png 1920w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/35cd4240bf50f0e0ea16072e8d0e23ad/8dd4b/solidworks.png",
              "alt": "SolidWorks mechanical design - Magnus Code - Malaysia",
              "title": "SolidWorks mechanical design - Magnus Code - Malaysia",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>
        <Caption fullWidth mdxType="Caption">Mechanical design in SolidWorks</Caption>
        <Row mdxType="Row">
  Create, analyse and improve any part or assembly with our mechanical design
  solutions.
        </Row>
        <Row mdxType="Row">
  With internationally certified CAD designing capabilities, Magnus Code brings
  about a whole new level of possibilities that can be utilised for creating the
  best product with minimum inaccuracy.
        </Row>
        <Row mdxType="Row">
  Through the usage of industry leading software, we ensure you receive high
  quality service for all your CAD designing needs.
        </Row>
      </Column>
      <Column colMd={2} colLg={3} offsetMd={1} offsetLg={1} mdxType="Column">
        <Aside aria-label="Example aside" mdxType="Aside">
          <p><strong parentName="p">{`Mechanical design.`}</strong></p>
          <p>{`We are certified mechanical design experts. Our design meets DFM standards.`}</p>
        </Aside>
      </Column>
    </Row>
    <Row className="resource-card-group mt-4 " mdxType="Row">
  <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="Talk to our team" title="Contact Us" aspectRatio="2:1" actionIcon="arrowRight" href="/contact" color="light" className="blue-card" mdxType="ResourceCard"></ResourceCard>
  </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      